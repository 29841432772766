<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="核查人">
          <el-input v-model="searchForm.checkPerson" placeholder="请输入核查人" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="巡查人">
          <el-input v-model="searchForm.patrolPerson" placeholder="请输入巡查人" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="NFCList" stripe style="width: 100%" height="100%">
        <el-table-column prop="checkResult" label="检查结果" show-overflow-tooltip fixed width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="default" @click="open('result',scope.row)" v-show="scope.row.checkResult===0">自我检查合格</el-button>
            <el-button type="text" size="default" @click="open('result',scope.row)" v-show="scope.row.checkResult===1">现场整改</el-button>
            <el-button type="text" size="default" @click="open('result',scope.row)" v-show="scope.row.checkResult===2">限期整改</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="checkTime" label="核查时间" show-overflow-tooltip width="120" align="center">
          <template slot-scope="scope">
            {{scope.row.checkTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="rectifyDays" label="整改时间" show-overflow-tooltip width="120" align="center">
          <template slot-scope="scope">
            {{scope.row.rectifyDays|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="patrolPerson" label="巡查人" show-overflow-tooltip width="100" align="center">
        </el-table-column>
        <el-table-column prop="checkPerson" label="核查人" show-overflow-tooltip width="100" align="center">
        </el-table-column>
        <el-table-column prop="safeHead" label="安全主管" show-overflow-tooltip width="100" align="center">
        </el-table-column>
        <el-table-column prop="isFire" label="用火、用电有无违章情况" show-overflow-tooltip width="180" align="center">
          <template slot-scope="scope">
            <el-tag type="success" size="normal" v-show="scope.row.isFire===1">否</el-tag>
            <el-tag type="danger" size="normal" v-show="scope.row.isFire===0">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isExit" label="安全出口、安全疏散通道是否畅通" show-overflow-tooltip width="240" align="center">
          <template slot-scope="scope">
            <el-tag type="success" size="normal" v-show="scope.row.isExit===1">否</el-tag>
            <el-tag type="danger" size="normal" v-show="scope.row.isExit===0">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isLight" label="安全疏散疏散指示标志、应急照明是否完好" show-overflow-tooltip width="300" align="center">
          <template slot-scope="scope">
            <el-tag type="success" size="normal" v-show="scope.row.isLight===1">否</el-tag>
            <el-tag type="danger" size="normal" v-show="scope.row.isLight===0">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isFlag" label="消防设施、器材和消防安全标志是否到位、完整" show-overflow-tooltip width="320" align="center">
          <template slot-scope="scope">
            <el-tag type="success" size="normal" v-show="scope.row.isFlag===1">否</el-tag>
            <el-tag type="danger" size="normal" v-show="scope.row.isFlag===0">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isDoor" label="常闭式防火门是否处于关闭状态" show-overflow-tooltip width="220" align="center">
          <template slot-scope="scope">
            <el-tag type="success" size="normal" v-show="scope.row.isDoor===1">否</el-tag>
            <el-tag type="danger" size="normal" v-show="scope.row.isDoor===0">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isThing" label="防火卷帘下是否堆放物品影响使用" show-overflow-tooltip width="240" align="center">
          <template slot-scope="scope">
            <el-tag type="success" size="normal" v-show="scope.row.isThing===1">否</el-tag>
            <el-tag type="danger" size="normal" v-show="scope.row.isThing===0">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isPerson" label="消防安全重点部位的人员在岗情况" show-overflow-tooltip width="240" align="center">
          <template slot-scope="scope">
            <el-tag type="success" size="normal" v-show="scope.row.isPerson===1">否</el-tag>
            <el-tag type="danger" size="normal" v-show="scope.row.isPerson===0">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isOthers" label="其他消防安全情况" show-overflow-tooltip width="150" align="center">
          <template slot-scope="scope">
            <el-tag type="success" size="normal" v-show="scope.row.isOthers===1">否</el-tag>
            <el-tag type="danger" size="normal" v-show="scope.row.isOthers===0">是</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 详情 -->
    <el-drawer :title="resultText" :visible.sync="NFCShow" direction="rtl" :before-close="handleClose" size="33%">
      <el-button type="success" size="small" icon="el-icon-printer">打印</el-button>
      <el-form label-position="left" inline class="drawer-form">
        <el-form-item label="检查结果" :label-width="formLabelWidth">
          <span v-show="NFC.checkResult===0">自我检查合格</span>
          <span v-show="NFC.checkResult===1">现场整改</span>
          <span v-show="NFC.checkResult===2">限期整改</span>
        </el-form-item>
        <el-form-item label="巡查人" :label-width="formLabelWidth">
          <span>{{NFC.patrolPerson}}</span>
        </el-form-item>
        <el-form-item label="核查人" :label-width="formLabelWidth">
          <span>{{NFC.checkPerson}}</span>
        </el-form-item>
        <el-form-item label="安全主管" :label-width="formLabelWidth">
          <span>{{NFC.safeHead}}</span>
        </el-form-item>
        <el-form-item label="用火、用电有无违章情况" :label-width="formLabelWidth">
          <el-tag type="success" size="normal" v-show="NFC.isFire===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="NFC.isFire===0">是</el-tag>
        </el-form-item>
        <el-form-item label="安全出口、安全疏散通道是否畅通" :label-width="formLabelWidth">
          <el-tag type="success" size="normal" v-show="NFC.isExit===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="NFC.isExit===0">是</el-tag>
        </el-form-item>
        <el-form-item label="安全疏散疏散指示标志、应急照明是否完好" :label-width="formLabelWidth">
          <el-tag type="success" size="normal" v-show="NFC.isLight===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="NFC.isLight===0">是</el-tag>
        </el-form-item>
        <el-form-item label="消防设施、器材和消防安全标志是否到位、完整" :label-width="formLabelWidth">
          <el-tag type="success" size="normal" v-show="NFC.isFlag===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="NFC.isFlag===0">是</el-tag>
        </el-form-item>
        <el-form-item label="常闭式防火门是否处于关闭状态" :label-width="formLabelWidth">
          <el-tag type="success" size="normal" v-show="NFC.isDoor===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="NFC.isDoor===0">是</el-tag>
        </el-form-item>
        <el-form-item label="防火卷帘下是否堆放物品影响使用" :label-width="formLabelWidth">
          <el-tag type="success" size="normal" v-show="NFC.isThing===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="NFC.isThing===0">是</el-tag>
        </el-form-item>
        <el-form-item label="消防安全重点部位的人员在岗情况" :label-width="formLabelWidth">
          <el-tag type="success" size="normal" v-show="NFC.isPerson===1">是</el-tag>
          <el-tag type="danger" size="normal" v-show="NFC.isPerson===0">否</el-tag>
        </el-form-item>
        <el-form-item label="其他消防安全情况" :label-width="formLabelWidth">
          <el-tag type="success" size="normal" v-show="NFC.isOthers===1">是</el-tag>
          <el-tag type="danger" size="normal" v-show="NFC.isOthers===0">否</el-tag>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentPage: 1,
      total: 0,
      NFCList: [],
      searchForm: {},
      formLabelWidth: '320px',
      NFCShow: false,
      NFC: {},
      resultText: ''
    }
  },
  created () {
    this.initTable()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable () {
      const data = {}
      if (this.searchForm.checkPerson) {
        data.checkPerson = this.searchForm.checkPerson
      }
      if (this.searchForm.patrolPerson) {
        data.patrolPerson = this.searchForm.patrolPerson
      }
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/nfcPatrol/list', { params: data })
      if (result.code === 200) {
        this.NFCList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async open (flag, row) {
      switch (flag) {
        case 'result': {
          this.NFC = row
          switch (row.checkResult) {
            case 0:
              this.resultText = '自我检查合格'
              break
            case 1:
              this.resultText = '现场整改'
              break
            case 2:
              this.resultText = '限期整改'
              break
          }
          this.NFCShow = true
        }
      }
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
.drawer-form {
  font-size: 0;
  padding: 10px 40px;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
      float: left;
    }
    ::v-deep .el-form-item__content {
      float: left;
    }
  }
}
::v-deep  :focus {
  outline: 0;
}
</style>
